<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="500">
      <v-card scrollable persistent>
          <v-card-title>Create Relation Outbox<v-spacer></v-spacer>
            <v-icon :color="color.theme" dense dark size="35">device_hub</v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
              <v-layout row wrap align-center justify-center>
                  <v-flex xs11 lg11 ma-5>
                    <v-card>
                        <v-container fluid >
                            <v-row align="center">
                            <v-col cols="12">
                                <div v-if="loaddataprogress === true" class="text-center">
                                  <v-progress-circular indeterminate size="64" :color="color.theme">
                                    <span :style="colorProgress">&nbsp; &nbsp; loading</span>
                                  </v-progress-circular>
                                </div>
                                <v-layout row wrap justify-center>
                                    <v-flex lg10 xs10 class="text-center">
                                        <v-text-field
                                        label="NameRelation"
                                        v-model="namerelation"
                                        persistent-hint
                                        outlined
                                        dense
                                        >
                                        </v-text-field>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                    <v-flex lg10 xs10 class="text-center">
                                       <v-text-field
                                        label="Filename"
                                        :value="Filenamerelation"
                                        persistent-hint
                                        outlined
                                        dense
                                        readonly
                                        >
                                        </v-text-field>
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                  <v-flex lg10 xs10 class="text-center">
                                    <div v-for="(item,i) in statusrelation" :key="i">
                                       <v-checkbox v-model="selected" :label="item" :value="item"></v-checkbox>
                                    </div>
                                  </v-flex>
                                </v-layout>
                            </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                  </v-flex>
              </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
              <v-spacer></v-spacer>
             <v-btn
              color="red"
              outlined
              @click="$emit('closedialog'),selected=[],namerelation=''"
            >{{$t('uploadfolder.buttonclose') }}</v-btn>
            <v-btn
            @click="apiAdd()"
             :color="color.theme"
            dark
            >{{$t('changeName.submit')}}</v-btn>

          </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});
export default {
  data (){
    return{
      id:'',
      name:'',
      Filenamerelation:[],
      newdata:'',
      namerelation:'',
      statusrelation:[],
      newdatamodel:[],
      selected:[],
      loaddataprogress: true,
    }
  },
  props: ["show","fileoutbox"],
  computed:{
  ...mapState(["username", "authorize", "account_active", "color"]),
  ...mapState({ processloader: "loading" }),
  ...mapGetters([
    "dataUsername",
    "dataAuthorize",
    "dataAccountActive",
    "dataAccesstoken",
    "dataBusinessProfile",
    "dataCitizenProfile",
    "dataDepartmentAccessId",
    "dataAccountId"
  ]),
    shower: {
    get() {
      if (this.show === true) {
        this.checkloaddata();
        this.checkstatus();
      }
      return this.show;
    }
  },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    }
  },
    methods:{
      async apiAdd(){
            this.newdata = ''
            this.newdatamodel = [];
            let payload;
            if (this.namerelation === "") {
                Toast.fire({
                icon: "error",
                title: "Error Please enter name "
              });
            }
            else{
            console.log("selected",this.selected);
              for(let i = 0; i < this.file.length;i++ ){
                for(let j =0; j<this.selected.length;j++){
                    if(this.file[i].filename === this.selected[j]){
                       this.newdatamodel.push(this.file[i].inbox_id)
                      }
                  }
                }
                console.log("this.newdatamodel+++",this.newdatamodel);
              if(this.Filenamerelation === this.fileoutbox.filename){
                    this.newdata = this.fileoutbox.inbox_id
                }
            payload={
                inbox_root:this.newdata,
                inbox_id_child:this.newdatamodel,
                account_id:this.dataAccountId,
                relation_name:this.namerelation
            }
            console.log(payload);
            let auth = await gbfGenerate.generateToken();
           await this.axios
                .post(process.env.VUE_APP_SERVICE_UPDATE_INBOX + "/api/insert_relation_inbox_with_child",payload, { headers: { Authorization: auth.code },},)
                .then(response =>{
                    if (response.data.status === "OK") {
                      Toast.fire({
                        icon: "success",
                            title: "Success"
                         });
                        this.id = response.data.relation_id
                        this.name = response.data.relation_name
                        console.log({relation_id:this.id,relation_name:this.name});
                        this.$emit('checkparameter',{relation_id:this.id,relation_name:this.name})
                        this.namerelation= ''
                        this.selected=[]
                    }else{
                        Toast.fire({
                            icon: "error",
                            title: "Error"
                         });
                    }
                
                })
            }
        },
    
    checkstatus(){
      this.Filenamerelation = this.fileoutbox.filename
    },
    checkloaddata(){
      let payload
        this.statusrelation = []
         payload = {
        limit: "",
        offset: "",
        sender_id: [this.dataAccountActive.business_info["business_id"]],
        sender_type: "2",
        filename: "",
        fileextension: "",
        doc_no_start: "",
        doc_no_end: "",
        doc_date_start: "",
        doc_date_end: "",
        doc_type: "",
        cre_dtm_start: "",
        cre_dtm_end: "",
        sender_name: "",
        system: this.$route.query.systemid,
        sellerTaxid: "",
        sellerBranchid: "",
        receiver_name: "",
        receiverTaxid: "",
        receiverBranchid: "",
        status: ""
      }
        this.axios
        .post(
        process.env.VUE_APP_SERVICE_GET_OUTBOX + "/api/get_file_outbox_child",
        payload
        )   
          .then(response=>{
        if (response.data.status === "OK") {

          this.file = response.data.result.data
          for(let i = 0; i <this.file.length; i++){
              if(this.file[i].relation_status === 2 && this.file[i].status === "active"){
                this.statusrelation.push(this.file[i].filename)
               
              }
          }
          this.loaddataprogress = false;
          console.log("statusrelation",this.statusrelation);
        // this.loaddataprogress=false
        }
        })          
      }
  }
}
</script>
<style >

</style>